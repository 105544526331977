// ================================= color =========================================
// 基础颜色
$color-blue: #2660ff;
$color-green: #18be6b;
$color-orange: #ffbf00;
$color-orange-dark: #ff7e00;
$color-red: #f33f3f;
$color-black: #000;
$color-white: #fff;
$color-gray-light: #f8f8f8;
$color-gray: #ccc;
$color-gray-dark: #333;

// 文字颜色
$color-text: #000;
$color-text-1: #333;
$color-text-2: #666;
$color-text-3: #999;
$color-text-4: #ccc;

// 边框颜色
$color-border: #e6e6e6;
$color-border1: #f1f1f1;

// 背景颜色
$color-bg: #fff;
$color-bg1: #000;
$color-bg2: #f8f8f8;
$color-bg3: rgba(0, 0, 0, 0.5);

// 圆角
$radius-xl: 32px;
$radius-lg: 24px;
$radius-md: 16px;
$radius-base: 8px;
$radius-sm: 4px;
$radius-full: 9999px;

// 字体大小
$text-xs: 24px;
$text-sm: 28px;
$text-base: 32px;
$text-lg: 36px;

// 字体粗细
$font-normal: 400;
$font-bold: 700;

// 间距
$gap-none: 0px;
$gap-xxs: 10px;
$gap-xs: 20px;
$gap-sm: 30px;
$gap-md: 60px;
$gap-lg: 80px;
$gap-xl: 100px;

// js使用
:export {
  colorBlue: $color-blue;
  colorGreen: $color-green;
  colorYellow: $color-orange;
  colorRed: $color-red;
  colorGray: $color-gray;
  colorBlack: $color-black;
  colorWhite: $color-white;
}
