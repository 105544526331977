@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@/src/styles/variable.scss";
@import "@/src/styles/mixin.scss";

:root {
  --background: #ffffff;
  --foreground: #171717;
}

// 暗黑模式
// @media (prefers-color-scheme: dark) {
//   :root {
//     --background: #0a0a0a;
//     --foreground: #ededed;
//   }
// }

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }

  .text-balance-old {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    hyphens: auto;
  }
}

html,
body {
  color: var(--foreground);
  background: var(--background);
  font-family: Arial, Helvetica, sans-serif;
  width: 100% !important;
  height: 100% !important;
}

.scroll-plain {
  @include scroll-bar-plain;
}

.scroll-hide {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}

.page-container {
  margin: 0 auto;
  min-height: 500px;
  max-width: 1200px;
}

.header-container {
  .ant-menu-horizontal {
    // border-bottom: none;
  }
  .ant-menu-item {
    display: flex !important;
    align-items: center;

    &::after {
      border-bottom-width: 4px !important;
      border-radius: 999px;
    }
  }

  @media (screen(mt)) {
    .ant-menu-item {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
}
