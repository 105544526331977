@mixin scroll-bar-plain {
  // 内层轨道，滚动条中间部分
  &::-webkit-scrollbar-track-piece {
    background: $color-gray-light;
  }

  //   滚动条整体部分
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  //   滚动条里面的小方块
  &::-webkit-scrollbar-thumb {
    background-color: $color-gray;
    border-radius: 999px;
  }

  //   滚动条的轨道
  &::-webkit-scrollbar-track {
    border-radius: 999px;
  }
}
